<template>
  <div>
    <p @click='emit'>{{msg}}</p>
    <p v-on:wash_Goods="arg"></p>
  </div>
  <div id="mydiv" draggable="true" @dragstart="dragStart($event, index, field)" @drag="ondrag($event, index, field)"
    @dragend="dragend($event, index, field)">
    <button @click="close($event)">close</button>
    <div id="mydivheader">jobjobjob</div>
    <p>Move</p>
    <p>this</p>
    <p>DIV</p>

  </div>
  <!-- <p @mouseover="displayImg('chen')" @mouseleave="vanishImg()" @mousemove="displayImg('chen')">work like a man</p> -->
  <!-- <p @mouseover="displayImg('chen')">work like a man</p> -->
  <div id="image1" style="display:none">
  </div>
  <p>job2</p>
</template>

<script>
  import * as d3 from 'd3'
  var that
  export default {
    name: 'Test',
    data () {
      return {
        msg: '点击后派发事件',
        moveStartLeft: 0,
        moveStartTop: 0,
        moveEndX: 0,
        moveEndY: 0,
      }
    },
    created () {
      that = this
      // action.dragElement(document.getElementById("mydiv"))
    },
    methods: {
      emit () {
        that.emit2("arg2")
      },
      emit2 (arg) {

      },
      displayImg (play) {
        var img = document.getElementById("image1");
        var x = event.clientX + document.body.scrollLeft + 20;
        var y = event.clientY + document.body.scrollTop - 5;
        // var x = event.clientX;
        // var y = event.clientY;
        img.style.position = "absolute"
        img.style.left = x + "px";
        img.style.top = y + "px";
        img.style.display = "block";
      },
      vanishImg () {
        var img = document.getElementById("image1");
        img.style.display = "none";
      },
      dragStart (e, index, field) {
        this.startLeft = e.x
        this.startTop = e.y
      },
      ondrag (e, index, field) {
      },
      dragend (e, index, field) {
        var startLeft = e.target.offsetLeft
        var startTop = e.target.offsetTop
        var elem = document.getElementById(e.target.id);
        elem.style.left = startLeft + e.x - this.startLeft + "px";
        elem.style.top = startTop + e.y - this.startTop + "px";
      },
      close (e) {
        var parentElement = e.target.parentElement
        if (parentElement) {
          parentElement.remove();
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  #mydiv {
    position: absolute;
    z-index: 9;
    background-color: #f1f1f1;
    border: 1px solid #d3d3d3;
    text-align: center;
    cursor: move;
  }

  #mydivheader {
    padding: 10px;
    cursor: move;
    z-index: 10;
    background-color: #2196F3;
    color: #fff;
  }
</style>